export interface Section {
  type: SectionTypes;
  visible: boolean;
}

export enum SectionTypes {
  DESCRIPTION = 'DESCRIPTION',
  TITLE_TAGLINE = 'TITLE_TAGLINE',
  CONTACT = 'CONTACT',
  DETAILS = 'DETAILS',
  POLICY = 'POLICY',
  SCHEDULING = 'SCHEDULING',
}
